import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { formatPhone, parseFirstNumber } from '../../helpers';
import { SEND_FORM_FOSTER } from '../../api/mutations';
import { STATES } from '../../constants';
import yupTypes from '../../types/yupTypes';

import Section from '../layout/Section';
import FormLayout from '../layout/FormLayout';
import Input from '../inputs';

const Foster = (): ReactElement => {
  const [sendFormFoster] = useMutation(SEND_FORM_FOSTER);
  const history = useHistory();
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleSubmit = () => {
    setCanSubmit(false);
    setSubmitFailed(false);
    setIsSubmitting(true);
    const variables = {
      ...formik.values,
      preferredGender: formik.values.preferredGender.join(', '),
      preferredSize: formik.values.preferredSize.join(', '),
      preferredAge: formik.values.preferredAge.join(', '),
    };
    sendFormFoster({ variables })
      .then((res) => handleResponse(res))
      .catch((err) => handleResponse(err));
  };

  // handle form submission response, if successful, send to confirmation page
  const handleResponse = (res: any) => {
    const status = res.data?.sendFormFoster?.status;
    const formNumber = res.data?.sendFormFoster?.orderNumber;
    setIsSubmitting(false);
    if (status === 'success') {
      history.push(`/forms/foster/confirmation?formNumber=${formNumber}`);
    } else {
      setCanSubmit(true);
      setSubmitFailed(true);
      console.log('Form submission error');
    }
  };

  const petInfoSchema = (name: any, minValue: number) => {
    return yupTypes.shortAnswer.when(name, (value: any, schema: any) => {
      if (parseFirstNumber(value) >= minValue) {
        return schema.required('Required.');
      } else {
        return schema;
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      residenceType: '',
      rentOrOwn: '',
      adults: '',
      familiarWithDogs: '',
      allergiesToDogs: '',
      numPets: '',
      expectations: '',
      experience: '',
      lookingToAdopt: '',
      petsDied: '',
      explainPetsDied: '',
      hoursLeftAlone: '',
      primaryCaretaker: '',
      careAssistance: '',
      secondaryCaretaker: '',
      petsGetAlong: '',
      whereKeptDay: '',
      whereKeptNight: '',
      hasFence: '',
      describeConfinement: '',
      hasIndoorConfinement: '',
      describeIndoorConfinement: '',
      hasOutdoorShelter: '',
      describeOutdoorShelter: '',
      hasIsolationArea: '',
      describeIsolationArea: '',
      canAcceptStray: '',
      describeFeeding: '',
      typeOfFood: '',
      hasConsideredNegatives: '',
      canCareForSick: '',
      canGiveMedicine: '',
      canCareFixed: '',
      injuryIsUpsetting: '',
      canTransport: '',
      preferredGender: [],
      preferredSize: [],
      preferredAge: [],
      lengthOfCare: '',
      canAcceptLitter: '',
      canProvide: '',
      isTemporary: '',
      veterinarian: '',
      vetPhone: '',
      reference: '',
      refPhone: '',
      petName1: '',
      petType1: '',
      petBreed1: '',
      petAge1: '',
      petName2: '',
      petType2: '',
      petBreed2: '',
      petAge2: '',
      petName3: '',
      petType3: '',
      petBreed3: '',
      petAge3: '',
      petName4: '',
      petType4: '',
      petBreed4: '',
      petAge4: '',
      petName5: '',
      petType5: '',
      petBreed5: '',
      petAge5: '',
      petName6: '',
      petType6: '',
      petBreed6: '',
      petAge6: '',
    },
    validationSchema: yup.object({
      fullName: yupTypes.shortAnswer.required('Required.'),
      email: yupTypes.email,
      phone: yupTypes.phone.required('Required.'),
      address: yupTypes.shortAnswer.required('Required.'),
      city: yupTypes.shortAnswer.required('Required.'),
      state: yupTypes.string,
      zip: yupTypes.zip.required('Required.'),
      residenceType: yupTypes.shortAnswer.required('Required.'),
      rentOrOwn: yupTypes.string,
      adults: yupTypes.string,
      familiarWithDogs: yupTypes.string,
      allergiesToDogs: yupTypes.string,
      numPets: yupTypes.string,
      expectations: yupTypes.longAnswer.required('Required.'),
      experience: yupTypes.longAnswer.required('Required.'),
      lookingToAdopt: yupTypes.string,
      petsDied: yupTypes.string,
      explainPetsDied: yupTypes.longAnswer.required('Required.'),
      hoursLeftAlone: yupTypes.string,
      primaryCaretaker: yupTypes.shortAnswer.required('Required'),
      careAssistance: yupTypes.shortAnswer.required('Required'),
      secondaryCaretaker: yupTypes.shortAnswer.required('Required'),
      petsGetAlong: yupTypes.longAnswer.required('Required'),
      whereKeptDay: yupTypes.longAnswer.required('Required'),
      whereKeptNight: yupTypes.longAnswer.required('Required'),
      hasFence: yupTypes.shortAnswer.required('Required'),
      describeConfinement: yupTypes.longAnswer.required('Required'),
      hasIndoorConfinement: yupTypes.shortAnswer.required('Required'),
      describeIndoorConfinement: yupTypes.longAnswer.required('Required'),
      hasOutdoorShelter: yupTypes.shortAnswer.required('Required'),
      describeOutdoorShelter: yupTypes.longAnswer.required('Required'),
      hasIsolationArea: yupTypes.shortAnswer.required('Required'),
      describeIsolationArea: yupTypes.longAnswer.required('Required'),
      canAcceptStray: yupTypes.shortAnswer.required('Required'),
      describeFeeding: yupTypes.longAnswer.required('Required'),
      typeOfFood: yupTypes.shortAnswer.required('Required'),
      hasConsideredNegatives: yupTypes.shortAnswer.required('Required'),
      canCareForSick: yupTypes.shortAnswer.required('Required'),
      canGiveMedicine: yupTypes.shortAnswer.required('Required'),
      canCareFixed: yupTypes.shortAnswer.required('Required'),
      injuryIsUpsetting: yupTypes.shortAnswer.required('Required'),
      canTransport: yupTypes.shortAnswer.required('Required'),
      lengthOfCare: yupTypes.shortAnswer.required('Required'),
      canAcceptLitter: yupTypes.shortAnswer.required('Required'),
      canProvide: yupTypes.shortAnswer.required('Required'),
      isTemporary: yupTypes.shortAnswer.required('Required'),
      veterinarian: yupTypes.shortAnswer.required('Required'),
      vetPhone: yupTypes.phone.required('Required'),
      reference: yupTypes.shortAnswer.required('Required'),
      refPhone: yupTypes.phone.required('Required'),
      // pet 1
      petName1: petInfoSchema('numPets', 1),
      petType1: petInfoSchema('numPets', 1),
      petBreed1: petInfoSchema('numPets', 1),
      petAge1: petInfoSchema('numPets', 1),
      // pet 2
      petName2: petInfoSchema('numPets', 2),
      petType2: petInfoSchema('numPets', 2),
      petBreed2: petInfoSchema('numPets', 2),
      petAge2: petInfoSchema('numPets', 2),
      // pet 3
      petName3: petInfoSchema('numPets', 3),
      petType3: petInfoSchema('numPets', 3),
      petBreed3: petInfoSchema('numPets', 3),
      petAge3: petInfoSchema('numPets', 3),
      // pet 4
      petName4: petInfoSchema('numPets', 4),
      petType4: petInfoSchema('numPets', 4),
      petBreed4: petInfoSchema('numPets', 4),
      petAge4: petInfoSchema('numPets', 4),
      // pet 5
      petName5: petInfoSchema('numPets', 5),
      petType5: petInfoSchema('numPets', 5),
      petBreed5: petInfoSchema('numPets', 5),
      petAge5: petInfoSchema('numPets', 5),
      // pet 6
      petName6: petInfoSchema('numPets', 6),
      petType6: petInfoSchema('numPets', 6),
      petBreed6: petInfoSchema('numPets', 6),
      petAge6: petInfoSchema('numPets', 6),
    }),
    onSubmit: () => handleSubmit(),
  });

  const submitErrors = {
    required: () => {
      if (Object.keys(formik.errors).length > 0)
        return 'Please fix required fields.';
    },
    failed: () => {
      if (submitFailed)
        return 'Form submission failed. Please try again later or contact WAG.';
    },
  };

  // format phone number input, then update formik value
  const handlePhone = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    let value = e.target.value;
    value = formatPhone(value) || '';
    formik.setFieldValue(key, value);
  };

  // if number of owned pets changes, erase field data
  useEffect(() => {
    formik.setFieldValue('petName1', '');
    formik.setFieldValue('petType1', '');
    formik.setFieldValue('petBreed1', '');
    formik.setFieldValue('petAge1', '');
    formik.setFieldValue('petName2', '');
    formik.setFieldValue('petType2', '');
    formik.setFieldValue('petBreed2', '');
    formik.setFieldValue('petAge2', '');
    formik.setFieldValue('petName3', '');
    formik.setFieldValue('petType3', '');
    formik.setFieldValue('petBreed3', '');
    formik.setFieldValue('petAge3', '');
    formik.setFieldValue('petName4', '');
    formik.setFieldValue('petType4', '');
    formik.setFieldValue('petBreed4', '');
    formik.setFieldValue('petAge4', '');
    formik.setFieldValue('petName5', '');
    formik.setFieldValue('petType5', '');
    formik.setFieldValue('petBreed5', '');
    formik.setFieldValue('petAge5', '');
    formik.setFieldValue('petName6', '');
    formik.setFieldValue('petType6', '');
    formik.setFieldValue('petBreed6', '');
    formik.setFieldValue('petAge6', '');
    //eslint-disable-next-line
  }, [formik.values.numPets]);

  // check for errors and enable submitting
  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formik.errors]);

  return (
    <Section>
      <h1>Foster Dog Application</h1>
      <p>
        Please fill out this application if you are interested in fostering a
        WAG dog. If you would like more information about fostering or about
        WAG, feel free to contact us.
      </p>
      <Link to="/contact" className="btn btn--large btn--primary mb-2">
        Contact Us
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <br />
        <p className="text-red-800">* Required</p>
        {/* User's contact info */}
        <h2 className="mt-6 mb-0">Your Information</h2>
        <p className="mb-4 text-sm">
          If you choose to add an email, you will receive a copy of your
          application.
        </p>
        <Input.FormikInput
          required
          keyName="fullName"
          className="max-w-sm"
          label="Full Name:"
          placeholder="First Last"
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          required
          keyName="address"
          className="max-w-sm"
          label="Address:"
          placeholder="123 Address Rd"
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          required
          keyName="city"
          className="max-w-sm"
          label="City:"
          placeholder="City"
          formik={formik}
          maxLength={64}
        />
        <div className="flex max-w-sm justify-between">
          <Input.FormikInput
            required
            type="select"
            keyName="state"
            className="w-44"
            label="State:"
            formik={formik}
            options={STATES}
          />
          <Input.FormikInput
            required
            keyName="zip"
            className="w-44"
            label="ZIP:"
            placeholder="12345"
            formik={formik}
            maxLength={12}
          />
        </div>
        <Input.FormikInput
          required
          keyName="phone"
          className="max-w-sm"
          label="Phone #:"
          placeholder="(123) 456-7890"
          formik={formik}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handlePhone(e, 'phone')
          }
          maxLength={14}
        />
        <Input.FormikInput
          keyName="email"
          className="max-w-sm"
          label="Email:"
          type="email"
          placeholder="your_email@email.com"
          formik={formik}
          maxLength={64}
        />
        <h2 className="mt-6 mb-2">Household Info</h2>
        <Input.FormikInput
          required
          keyName="residenceType"
          className="max-w-sm"
          label="Type of residence:"
          placeholder="House, Apartment, Etc."
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="rentOrOwn"
          className="w-44"
          label="Do you rent or own?:"
          formik={formik}
          options={['Rent', 'Own']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="adults"
          className="w-44"
          label="Number of adults in home:"
          formik={formik}
          options={['1', '2', '3', '4', '5', '6 or more']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="familiarWithDogs"
          className="w-44"
          label="Are all household members familiar with dogs?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="allergiesToDogs"
          className="w-44"
          label="Does anyone in the household have an allergy to dogs?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="numPets"
          className="w-44"
          label="How many pets do you already have in your home?:"
          formik={formik}
          options={['0', '1', '2', '3', '4', '5', '6 or more']}
        />
        {/* Add pet fields here */}
        {formik.values.numPets.length > 0 &&
          new Array(parseFirstNumber(formik.values.numPets))
            .fill('')
            .map((val, i) => {
              const key = i + 1;
              return (
                <div key={`petInfo${i}`}>
                  <h3 className="mt-5 mb-3">Pet {key} Info</h3>
                  <Input.FormikInput
                    keyName={`petName${key}`}
                    className="max-w-sm"
                    label={`Pet ${key} Name:`}
                    placeholder="Fido"
                    required
                    formik={formik}
                  />
                  <Input.FormikInput
                    keyName={`petType${key}`}
                    className="max-w-sm"
                    label={`Pet ${key} Type:`}
                    placeholder="Dog"
                    required
                    formik={formik}
                  />
                  <Input.FormikInput
                    keyName={`petBreed${key}`}
                    className="max-w-sm"
                    label={`Pet ${key} Breed:`}
                    placeholder="Golden Retriever"
                    required
                    formik={formik}
                  />
                  <Input.FormikInput
                    keyName={`petAge${key}`}
                    className="w-44"
                    label={`Pet ${key} Age:`}
                    placeholder="4 years"
                    required
                    formik={formik}
                  />
                </div>
              );
            })}
        <h2 className="mt-6 mb-2">Care and Expectations</h2>
        <Input.FormikInput
          required
          type="textarea"
          keyName="expectations"
          className="max-w-sm"
          label="What are your foster dog expectations?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="experience"
          className="max-w-sm"
          label="What previous pet experience do you have?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="lookingToAdopt"
          className="w-44"
          label="Are you currently looking for an animal to adopt?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="petsDied"
          className="w-44"
          label="Have you had any pets die or become lost in the last year?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="explainPetsDied"
          className="max-w-sm"
          label="If yes, please explain:"
          placeholder='Text here, or write "N/A"'
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="hoursLeftAlone"
          className="w-44"
          label="Approximate hours dog will be left alone per day?:"
          formik={formik}
          options={[
            '0 - 3 hours',
            '3 - 6 hours',
            '6 - 9 hours',
            '9 - 12 hours',
            'Over 12 hours',
          ]}
        />
        <Input.FormikInput
          required
          keyName="primaryCaretaker"
          className="max-w-sm"
          label="Who will be the primary caretaker for the dog?:"
          placeholder="Me, a member of my family, etc."
          formik={formik}
          maxLength={100}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="careAssistance"
          className="w-44"
          label="Will anyone assist in caring for the dog?:"
          formik={formik}
          options={['No', 'Yes, regularly', 'Yes, occasionally']}
        />
        <Input.FormikInput
          required
          keyName="secondaryCaretaker"
          className="max-w-sm"
          label="If yes, who will assist in taking care of the dog?:"
          placeholder="A member of my family, a friend, etc."
          formik={formik}
          maxLength={100}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="petsGetAlong"
          className="max-w-sm"
          label="How would your pets get along with a foster dog?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="whereKeptDay"
          className="max-w-sm"
          label="Where will the foster dog be kept during the day?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="whereKeptNight"
          className="max-w-sm"
          label="Where will the foster dog be kept during the night?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <h2 className="mt-6 mb-2">Areas and Shelter</h2>
        <Input.FormikInput
          required
          type="select"
          keyName="hasFence"
          className="w-44"
          label="Do you have a fenced yard?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="describeConfinement"
          className="max-w-sm"
          label="Describe your fence area and height or other method of confining the foster dog to your property:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="hasIndoorConfinement"
          className="w-44"
          label="Do you have an indoor area to confine the dog (spare room, crate, laundry room, etc.)?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="describeIndoorConfinement"
          className="max-w-sm"
          label="If yes, describe your indoor confinement area:"
          placeholder='Text here, or write "N/A"'
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="hasOutdoorShelter"
          className="w-44"
          label="Do you have an outdoor shelter available?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="describeOutdoorShelter"
          className="max-w-sm"
          label="If yes, describe your outdoor shelter:"
          placeholder='Text here, or write "N/A"'
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="hasIsolationArea"
          className="w-44"
          label="Do you have an area in which to isolate the foster dog from your own pets?:"
          formik={formik}
          options={['No', 'Yes']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="describeIsolationArea"
          className="max-w-sm"
          label="If yes, describe the isolation area:"
          placeholder='Text here, or write "N/A"'
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canAcceptStray"
          className="w-44"
          label="Are you willing to accept and hold a stray dog?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="describeFeeding"
          className="max-w-sm"
          label="Where and how often do you feed your pets?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={2000}
        />
        <Input.FormikInput
          required
          keyName="typeOfFood"
          className="max-w-sm"
          label="What type of food?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={100}
        />
        <h2 className="mt-6 mb-2">Fostering Considerations</h2>
        <Input.FormikInput
          required
          type="select"
          keyName="hasConsideredNegatives"
          className="w-44"
          label="Have you considered the negative aspects of fostering (e.g. cleaning up after puppies, clawing furniture, sleepless nights)?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canCareForSick"
          className="w-44"
          label="Are you able to care for a sick or injured dog on a temporary basis?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canGiveMedicine"
          className="w-44"
          label="Are you able to care administer medicine to a dog if necessary?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canCareFixed"
          className="w-44"
          label="Are you willing to care for a dog that has recently been spayed or neutered?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="injuryIsUpsetting"
          className="w-44"
          label="Would seeing an injured dog upset you enough that you would not be able to continue to care for the dog?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canTransport"
          className="w-44"
          label="Are you able to transport the foster dog to a designated veterinary clinic for care?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <h2 className="mt-6 mb-2">Foster Dog Preferences</h2>
        <Input.FormikInput
          type="checkbox"
          keyName="preferredGender"
          className="w-44"
          label="Preferred gender of dog you would like to foster:"
          formik={formik}
          options={['Male', 'Female']}
        />
        <Input.FormikInput
          type="checkbox"
          keyName="preferredSize"
          className="w-44"
          label="Preferred size of dog you would like to foster:"
          formik={formik}
          options={[
            'No preference',
            'Under 15 lbs',
            '15 lbs - 35 lbs',
            'Over 35 lbs',
          ]}
        />
        <Input.FormikInput
          type="checkbox"
          keyName="preferredAge"
          className="w-44"
          label="Preferred age of dog you would like to foster:"
          formik={formik}
          options={[
            'No preference',
            '0 - 1 year',
            '1 - 2 years',
            '2 - 5 years',
            '5 - 8 years',
            '8 - 12 years',
            'Over 12 years',
          ]}
        />
        <h2 className="mt-6 mb-2">Length of Care</h2>
        <Input.FormikInput
          required
          keyName="lengthOfCare"
          className="max-w-sm"
          label="How long are you willing to foster a dog?:"
          placeholder="Text here..."
          formik={formik}
          maxLength={100}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canAcceptLitter"
          className="w-44"
          label="Would you be willing to foster litters of puppies?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="canProvide"
          className="w-44"
          label="Are you willing to provide the dog food, toys, leashes, etc.?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <Input.FormikInput
          required
          type="select"
          keyName="isTemporary"
          className="w-44"
          label="Do you understand that fostering a dog is a temporary situation until the dog has found its permanent home?:"
          formik={formik}
          options={['No', 'Yes', 'Undecided']}
        />
        <h2 className="mt-6 mb-2">Contacts</h2>
        <Input.FormikInput
          required
          keyName="veterinarian"
          className="max-w-sm"
          label="I authorize WAG to contact my current veterinarian who is:"
          placeholder="Name and office"
          formik={formik}
          maxLength={100}
        />
        <Input.FormikInput
          required
          keyName="vetPhone"
          className="max-w-sm"
          label="Veterinarian phone #:"
          placeholder="(123) 456-7890"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handlePhone(e, 'vetPhone')
          }
          formik={formik}
        />
        <Input.FormikInput
          required
          keyName="reference"
          className="max-w-sm"
          label="I authorize WAG to contact a reference (non-relative) who is:"
          placeholder="Name"
          formik={formik}
          maxLength={100}
        />
        <Input.FormikInput
          required
          keyName="refPhone"
          className="max-w-sm"
          label="Reference phone #:"
          placeholder="(123) 456-7890"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handlePhone(e, 'refPhone')
          }
          formik={formik}
        />
        <p className='mb-4'>
          If you can send us pictures and/or videos of your yard it will help us
          process your application more quickly. Please send to:{' '}
          <strong>wagranch@yahoo.com</strong>
        </p>
        {/* submit button */}
        <button
          className="btn btn--large btn--primary mb-2"
          disabled={Object.keys(formik.errors).length > 0 || !canSubmit}
          type="submit"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        <FormLayout.Error>
          {submitErrors.failed()}
          {submitErrors.failed() && <br />}
          {submitErrors.required()}
          {submitErrors.required() && <br />}
        </FormLayout.Error>
      </form>
    </Section>
  );
};

export default Foster;
